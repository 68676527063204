import React, { Component } from 'react'
import './styles.css'

export default class Navbar extends Component {
  render() {
    return (
      <nav>
        <div>React Media Visualizer</div>
        <div className="links">Side Two</div>
      </nav>
    )
  }
}
